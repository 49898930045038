@layer components {
  .clip-rect {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }

  .clip-angled {
    clip-path: polygon(0 0, 100% var(--triangle-height), 100% 100%, 0 100%);
  }

  .clip-angled-inverse {
    clip-path: polygon(0 0, 0 var(--triangle-height), 100% 0, 100% 100%, 0 100%);
  }

  .clip-chevron {
    clip-path: polygon(0 0, 50% calc(var(--triangle-height) * 0.75), 100% 0, 100% 100%, 0 100%);
  }
}

@import 'tailwindcss/base';
@import './base';

@import 'tailwindcss/components';
@import './components';

@import 'tailwindcss/utilities';
@import './utilities';

:root {
  --ring: 215 20.2% 65.1%;

  --radius: 0.5rem;

  --triangle-height: 152px;
}

.dark {
  --ring: 216 34% 17%;

  --radius: 0.5rem;
}

@layer base {
  body {
    @apply text-body font-sans-serif;
    font-feature-settings: 'rlig' 1, 'calt' 1;
  }

  .oh-no-honey {
    @apply absolute top-0 left-0;
    @apply h-0 w-0;
    @apply z-[-1];
    @apply opacity-0;
  }
}
